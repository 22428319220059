/* eslint-disable max-len */
import React, { useCallback, useMemo, useState } from 'react';
import Head from 'next/head';
import Image from 'next/image';
import Link from 'next/link';
import { ButtonComponent, SlashContainer } from '@zolteam/axenergie-ui-library';
import { useRouter } from 'next/router';
import { useMeasure, useToggle } from 'react-use';

// Constants
import strings from '../../constants/Strings';
import colors from '../../constants/Colors';

// Components
import Menu from '../../components/Molecules/Menu';
import HeaderWithSlashContainer from '../../components/Atoms/HeaderWithSlashContainer';
import Footer from '../../components/Molecules/Footer';
import ReassuranceBanner from '../../components/Molecules/ReassuranceBanner';
import AgencyInfoBlock from '../../components/Atoms/AgencyInfoBlock';
import AgencyContactBlock from '../../components/Atoms/AgencyContactBlock';
import NewsBlock from '../../components/Molecules/NewsBlock';
import ReviewsBlock from '../../components/Molecules/ReviewsBlock';
import PrestationCard from '../../components/Atoms/PrestationCard';
import ContactModal from '../../components/Organisms/ContactModal';
import PaymentModal from '../../components/Organisms/PaymentModal';
import AdvertisementModal from '../../components/Organisms/AdvertisementModal';

// Services and hooks
import menuAndFooterService from '../../services/contentful/menuAndFooterService';
import AgencyService from '../../services/api/AgencyService';
import PrestationService from '../../services/api/PrestationService';
import useIsMobile from '../../hooks/useIsMobile';
import useLockBodyScrollNotIOS from '../../hooks/useLockBodyScrollNotIOS';

// Utils
import utils from '../../utils/formatCMS';
import headUtils from '../../utils/headUtils';
import urlUtils from '../../utils/urlUtils';
import { getHVACBusinessSchema, getAverageRating, getWorstRating } from '../../utils/seoUtils';
import prestationUtils from '../../utils/prestationUtils';

// Images
import logo from '../../public/Images/logo-white.webp';
import arrow from '../../public/icons/arrow-down.svg';
import whiteArrow from '../../public/icons/arrow-big-white.svg';
import redArrow from '../../public/icons/arrow-big.svg';

const AgencyDetail = (props) => {
  const router = useRouter();

  const [showPrestations, setShowPrestations] = useState(!router.query?.equipment);
  const [isPrestationBtnHover, setIsPrestationBtnHover] = useState(false);
  const [defaultModalSubject, setDefaultModalSubject] = useState(4);
  const [isWhoAreWeFold, setWhoAreWeFold] = useState(true);

  // modal related variables
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(!!router.query?.success || !!router.query?.failure || router.query?.payment === 'open');
  const [isAdvertisementModalOpen, setIsAdvertisementModalOpen] = useState(props.agency?.publicAdvertisementFilePath);
  const [locked, toggleLocked] = useToggle(!!router.query?.success || !!router.query?.failure || router.query?.payment === 'open');
  useLockBodyScrollNotIOS(locked);

  const [isMobile] = useIsMobile();
  const [contentBlockRef, { height: contentBlockHeight, width: contentBlockWidth }] = useMeasure();
  const [contactBlockRef, { height: contactBlockHeight }] = useMeasure();
  const [prestationButtonRef, { width: prestationButtonWidth }] = useMeasure();

  const toggleContactModal = (value) => {
    setIsContactModalOpen(value);
    toggleLocked();
  };
  const togglePaymentModal = (value) => {
    setIsPaymentModalOpen(value);
    toggleLocked(value);
  };
  const closeAdvertisementModal = () => {
    // sessionStorage.setItem('advertising-dismissed', 'true');
    setIsAdvertisementModalOpen(false);
  };

  const resetUrl = () => {
    delete router.query?.success;
    delete router.query?.failure;
    delete router.query?.id;
    router.replace(
      {
        pathname: urlUtils.getAgenceUrl({ id: props.agency.id, title: props.agency.name }),
        query: { ...router.query },
      },
      undefined,
      {
        shallow: true,
      },
    );
  };

  const sendDatalayerContact = (category) => {
    if (!window) {
      return;
    }
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'form-agency',
      category,
    });
  };

  const getDefaultSubject = (prestationType) => {
    switch (prestationType) {
      case 1:
        return 1; // correspond to siteContactSubject 1 : Je suis en panne
      case 2:
        return 2; // correspond to siteContactSubject 2 : Prendre rdv
      case 3:
        return 3; // correspond to siteContactSubject 3 : informations
      default:
        return 4; // correspond to siteContactSubject 4 : Autres
    }
  };

  const getCardWidth = () => (contentBlockWidth < 432 ? contentBlockWidth - 32 : 400);

  const titleTag = headUtils.formatPageTitle(`${strings.heatingProIn} ${props.agency.city} - ${props.agency.name}`);
  const descriptionTag = headUtils.formatMetaDescription(props.agency.description);

  /* useEffect(() => {
    if (sessionStorage.getItem('advertising-dismissed')) {
      setIsAdvertisingModalOpen(false);
    }
  }, []); */

  const toggleFold = useCallback(() => {
    setWhoAreWeFold(!isWhoAreWeFold);
  }, [isWhoAreWeFold]);

  const agencyPageDescription = useMemo(() => {
    const splitted = props.agency.pageDescription?.split('<h3>');
    if (splitted?.length > 1) {
      return (
        <div
          className="grey-600-text normal-weight mb-6 wysiwyg-text wysiwyg-content ws-pre-wrap"
        >
          <div>
            <span dangerouslySetInnerHTML={{ __html: `${splitted?.[0]}<h3>${splitted?.[1]}` }} />
            <div
              onClick={toggleFold}
              onKeyDown={toggleFold}
              tabIndex={-1}
              role="button"
              className="d-flex button bold text-primary mb-4"
            >
              <span>
                {isWhoAreWeFold ? strings.seeMore : strings.seeLess}
              </span>
              <Image
                alt="back-arrow"
                className={isWhoAreWeFold ? '' : 'rotate-180'}
                src={arrow}
                width={20}
              />
            </div>
          </div>
          <div
            dangerouslySetInnerHTML={{ __html: `<h3>${splitted.slice(2).join('<h3>')}` }}
            style={isWhoAreWeFold ? {
              opacity: 0,
              height: 0,
            } : null}
          />
        </div>
      );
    }
    return (
      <div
        className="grey-600-text normal-weight mb-6 wysiwyg-text wysiwyg-content ws-pre-wrap"
        dangerouslySetInnerHTML={{ __html: props.agency.pageDescription }}
      />
    );
  }, [props.agency.pageDescription, isWhoAreWeFold, toggleFold]);

  return (
    <div>
      <Head>
        <title>
          {titleTag}
        </title>
        <meta name="description" content={descriptionTag} />
        <meta property="og:title" content={titleTag} />
        <meta property="og:description" content={descriptionTag} />
        <meta
          property="og:image"
          content={`${process.env.NEXT_PUBLIC_IMAGE_PREFIX_URL}/public/${props.agency.illustrationFilePath}`}
        />
        <meta
          property="og:image:secure_url"
          content={`${process.env.NEXT_PUBLIC_IMAGE_PREFIX_URL}/public/${props.agency.illustrationFilePath}`}
        />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: getHVACBusinessSchema({
              ...props.agency,
              slug: router.query?.id,
              reviews: props.reviews,
              rating: {
                reviewCount: props.reviews?.length,
                value: getAverageRating(props.reviews),
                author: props.reviews?.reviewer,
                review: props.reviews,
                worstRating: getWorstRating(props.reviews),
              },
            }),
          }}
        />
      </Head>
      {/* header */}
      <div className="full-width d-flex f-row relative">
        {/* Menu */}
        <div className="absolute absolute-right-top-corner index-2">
          <Menu items={utils.formatCMSDatas('menu', props)} fontColor="grey-800-text" />
        </div>
        <HeaderWithSlashContainer
          backgroundColor={colors.grey800}
          imageUrl={props.agency.illustrationFilePath
            ? `${process.env.NEXT_PUBLIC_IMAGE_PREFIX_URL}/public/${props.agency.illustrationFilePath}`
            : ''}
          rightComponent={<AgencyInfoBlock agency={props.agency} />}
        >
          {isMobile ? null : (
            <Link href="/" className="mt-3 mb-4">
              <a><Image alt="logo" src={logo} width={206} height={206} /></a>
            </Link>
          )}
          <div className={`${isMobile ? 'mv-2 pl-3' : 'mb-6 mt-4 pl-5 ml-3'}`}>
            {props.hasNavigate ? (
              <ButtonComponent onClick={() => router.back()} theme="dark-grey">
                <div className="d-flex justify-center align-center">
                  <Image
                    alt="back-arrow"
                    className="rotate-90"
                    src={arrow}
                    width={20}
                  />
                  <span className="normal-weight">{strings.back}</span>
                </div>
              </ButtonComponent>
            ) : null}
            <div className="mh-3 mv-4" style={{ marginBottom: contactBlockHeight / 2 + 32 }}>
              <h1 className="medium-weight white-text mb-3 mt-3 fs-30 lh-2">
                {`${strings.heatingProIn} ${props.agency.city} - ${props.agency.name}`}
              </h1>
              <h2 className="h5 normal-weight white-text m-0">{props.agency.pageTitle}</h2>
              <div className="line grey-400-background opacity-01 mv-3" />
              <div
                className="grey-400-text normal-weight wysiwyg-text ws-pre-wrap"
                dangerouslySetInnerHTML={{ __html: props.agency.description }}
              />
            </div>
          </div>
        </HeaderWithSlashContainer>
      </div>
      {/* Content */}
      {/* Background grey slash */}
      <div
        className="relative full-width white-background align-center"
        style={{ height: contentBlockHeight + contactBlockHeight / 2 + 32 }}
      >
        <div className="absolute absolute-left-top-corner full-width">
          {isMobile ? null : (
            <SlashContainer width="41%" backgroundColor={colors.grey100} offset={20}>
              <div style={{ height: contentBlockHeight + contactBlockHeight / 2 + 32 }} />
            </SlashContainer>
          )}
        </div>
        {/* Grey slash content */}
        <div
          ref={contentBlockRef}
          className="absolute fgIndex full-width"
          style={{ marginTop: contactBlockHeight / 2 + 16 }}
        >
          <div className="ph-3">
            <div className="d-flex f-column align-center">
              <div style={{ width: isMobile ? '100%' : '80%' }}>
                <h2 className="h5 grey-800-text medium-weight">{strings.ourInterventionZone}</h2>
                <div
                  className="wysiwyg-text grey-600-text normal-weight"
                  dangerouslySetInnerHTML={{ __html: props.agency.descriptionIntervention }}
                />
              </div>
            </div>
            {/* Who are we block */}
            {
              props.agency.pageDescription
                ? (
                  <div className="full-width d-flex f-row mt-4">
                    <HeaderWithSlashContainer
                      key={isWhoAreWeFold}
                      backgroundColor={colors.white}
                      imageUrl={props.agency.pageIllustrationFilePath
                        ? `${process.env.NEXT_PUBLIC_IMAGE_PREFIX_URL}/public/${props.agency.pageIllustrationFilePath}`
                        : ''}
                    >
                      <div className={`${isMobile ? 'mv-2 ph-3' : 'mv-4 pl-5'}`}>
                        <div>
                          <h2 className="h2 grey-800-text medium-weight">{strings.whoAreWe}</h2>
                          {agencyPageDescription}
                        </div>
                        <div className="d-flex f-row f-wrap">
                          {
                            props.agency.pageLogosFiles?.map(({ filePath }) => (
                              <div
                                style={{ height: 70 }}
                                className="mv-2 mh-3"
                                key={filePath}
                              >
                                {/* eslint-disable-next-line @next/next/no-img-element */}
                                <img
                                  src={`${process.env.NEXT_PUBLIC_IMAGE_PREFIX_URL}/public/${filePath}`}
                                  className="full-height"
                                  alt="agency-qualifications"
                                />
                              </div>
                            ))
                          }
                        </div>
                        {(props?.agency?.generalTermsFilePath && props?.agency?.generalTermsFileId)
                          ? (
                            <div className="d-flex f-row f-wrap mt-2 red-link">
                              <span className="wysiwyg-text">
                                <a
                                  target="_blank"
                                  href={
                                    `${process.env.NEXT_PUBLIC_IMAGE_PREFIX_URL}/public/${props?.agency?.generalTermsFilePath}`
                                  }
                                  download
                                  rel="noreferrer"
                                >
                                  {strings.checkCGV}
                                </a>
                              </span>
                            </div>
                          )
                          : null}
                      </div>
                    </HeaderWithSlashContainer>
                  </div>
                )
                : null
            }
            {/* prestation block */}
            {
            props.prestations?.length && (showPrestations || router.query?.equipment)
              ? props.prestations.map(({
                name, id, networkPrestations, agencyPrestations,
              }) => {
                if ((!showPrestations && id.toString() === router.query?.equipment) || showPrestations) {
                  if (networkPrestations?.length || agencyPrestations?.length) {
                    return (
                      <div
                        key={id}
                        className="d-flex f-column align-center"
                      >
                        <div
                          className="d-flex f-column align-center justify-center full-width"
                        >
                          <h2
                            style={{ width: isMobile ? '100%' : '80%' }}
                            className="h2 grey-800-text medium-weight pt-4"
                          >
                            {name}
                          </h2>
                          <div className="d-flex f-row f-wrap justify-center">
                            {
                            [...networkPrestations, ...agencyPrestations]?.filter(({ isActive }) => isActive)?.map(({
                              title,
                              subtitle,
                              argumentation,
                              price,
                              prestationColorId,
                              id: prestationId,
                              agencyNetworkPrestation,
                              prestationTypeId,
                            }) => (
                              <div
                                key={prestationId}
                                className="d-flex mh-4 mv-3"
                                style={{ boxSizing: 'border-box' }}
                              >
                                <PrestationCard
                                  subtitle={prestationUtils.replaceAdherentName(subtitle, props.agency?.name)}
                                  width={getCardWidth()}
                                  argumentation={argumentation}
                                  price={price || agencyNetworkPrestation?.price}
                                  color={prestationColorId}
                                  subscribe={() => {
                                    setDefaultModalSubject(getDefaultSubject(prestationTypeId));
                                    toggleContactModal(true);
                                  }}
                                  className="full-height"
                                  link={urlUtils.getPrestationUrl(
                                    { id: prestationId, agencyId: props.agency.id, title },
                                  )}
                                  title={title}
                                  prestationType={prestationTypeId}
                                />
                              </div>
                            ))
                            }
                          </div>
                        </div>
                      </div>
                    );
                  }
                }
                return null;
              })
              : null
          }
            {showPrestations ? null : (
              <div className="d-flex f-column align-center">
                <p className="h2 grey-800-text medium-weight">
                  {`${props.agency.name}, ${strings.showPrestationTitle}`}
                </p>
                <div className="d-flex f-row f-wrap justify-center align-center full-width">
                  <p
                    className="grey-600-text normal-weight"
                    style={{
                      width: isMobile ? '100%' : contentBlockWidth * 0.8 - prestationButtonWidth - 128,
                    }}
                  >
                    {strings.showPrestationSubTitle}
                  </p>
                  <div
                    ref={prestationButtonRef}
                    className="mh-4"
                    onMouseLeave={() => setIsPrestationBtnHover(false)}
                    onMouseEnter={() => setIsPrestationBtnHover(true)}
                  >
                    <ButtonComponent onClick={() => setShowPrestations(true)} theme="outline">
                      <div
                        className="d-flex justify-center align-center large-button"
                      >
                        <span>{strings.allPrestations}</span>
                        <div className="mh-2">
                          {isPrestationBtnHover
                            ? <Image src={whiteArrow} width={30} alt="arrow" />
                            : <Image src={redArrow} width={30} alt="arrow" />}
                        </div>
                      </div>
                    </ButtonComponent>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {/* contact info block */}
        <div
          ref={contactBlockRef}
          className="absolute fgIndex full-width d-flex justify-center align-center"
          style={{
            top: `-${contactBlockHeight / 2}px`,
            left: 0,
          }}
        >
          <AgencyContactBlock
            agency={props.agency}
            beCalledBack={() => {
              setDefaultModalSubject(4);
              toggleContactModal(true);
            }}
            setIsPaymentModalOpen={togglePaymentModal}
            isFreeInvoicePaymentEnabled={props.agency?.isFreeInvoicePaymentEnabled}
          />
        </div>
      </div>
      {/* News Bloc */}
      {props.news?.length ? (
        <NewsBlock
          news={props.news}
          blockTitle={strings.ourNews}
        />
      ) : null}
      {props.reviews?.length ? (
        <ReviewsBlock
          reviews={props.reviews}
          blockTitle={`${strings.agencyReviews} ${props.agency.name}`}
          googleMyBusinessUrl={props.agency.googleMyBusinessUrl}
        />
      ) : null}
      {
        isContactModalOpen
          ? (
            <ContactModal
              closeModal={() => toggleContactModal(false)}
              source="PAGE AGENCE"
              defaultSubject={defaultModalSubject}
              agencyId={props.agency.id}
              logo={props.agency.logoFilePath}
              openingHours={props.agency.openingHours}
              agencyPhone={props.agency.phones?.[0]?.phone}
              sendDataLayerEvent={sendDatalayerContact}
            />
          )
          : null
      }
      {
        isPaymentModalOpen
          ? (
            <PaymentModal
              closeModal={() => {
                togglePaymentModal(false);
                resetUrl();
              }}
              agencyName={props.agency?.name}
              logo={props.agency?.logoFilePath}
              agencyId={props.agency?.id}
              openingHours={props.agency?.openingHours}
              agencyPhone={props.agency?.phones?.[0]?.phone}
              returnUrl={`https://${props.basePath}${router.asPath}`}
              isSuccess={!!router.query?.success}
              invoiceId={router.asPath?.split('id=')?.[1]}
              isFailure={!!router.query?.failure}
              legalOfficer={props.agency?.legalOfficer}
              sirenNumber={props.agency?.sirenNumber}
              businessName={props.agency?.businessName}
            />
          )
          : null
      }
      {
        isAdvertisementModalOpen
          ? (
            <AdvertisementModal
              closeModal={() => closeAdvertisementModal()}
              advertisement={props.agency?.publicAdvertisementFilePath}
            />
          )
          : null
          }
      {/* Reassurance banner */}
      <ReassuranceBanner items={utils.formatCMSReassurance(props)} />
      {/* Footer */}
      <Footer
        networkBtn={props.footerRseauLienBouton}
        clientItems={utils.formatCMSDatas('footerClient', props)}
        networkItems={utils.formatCMSDatas('footerRseau', props)}
        facebook={props.footerFacebook}
        instagram={props.footerInstagram}
        twitter={props.footerTwitter}
        mentions={props.mentions}
        legalItems={utils.formatCMSDatas('sousFooter', props)}
        isMobile={isMobile}
      />
    </div>
  );
};

export default AgencyDetail;

export async function getServerSideProps({ params, req }) {
  let menuAndFooter;
  let agency;
  let news;
  let prestations;
  let reviews;

  const agencyId = params?.id?.split('-')?.[0];

  try {
    menuAndFooter = await menuAndFooterService.menuAndFooter();
  } catch (err) {
    menuAndFooter = {};
    console.error(err);
  }

  try {
    agency = await AgencyService.getOneAgency(agencyId);

    if (!agency?.data?.isActive) {
      return {
        redirect: {
          destination: '/trouver-mon-chauffagiste',
          statusCode: 301,
        },
      };
    }
  } catch (err) {
    agency = {};
    console.error(err);
  }

  try {
    news = await AgencyService.getAgencyPageContents({
      agencyId,
      pad: 8,
    });
  } catch (err) {
    news = {};
    console.error(err);
  }

  try {
    prestations = await PrestationService.getPrestations(agencyId);
    // remove prestation with no price
    prestations = prestations?.data?.equipmentTypes.map((equipment) => ({
      ...equipment,
      networkPrestations: equipment.networkPrestations.filter((presta) => presta?.agencyNetworkPrestation?.price),
      agencyPrestations: equipment.agencyPrestations.filter((presta) => presta?.price),
    }));
  } catch (err) {
    prestations = {};
    console.error(err);
  }

  try {
    reviews = await AgencyService.getAgencyReviews({ agencyId, quantity: 3 });
  } catch (err) {
    reviews = [];
    console.error(err);
  }

  if (!agency?.data) {
    return {
      redirect: {
        destination: '/404',
        permanent: false,
      },
    };
  }
  return {
    props: {
      ...menuAndFooter?.fields,
      agency: agency?.data,
      news: news?.data?.agencyPageContents,
      prestations: prestations || [],
      reviews: reviews?.data?.agencyReviews || [],
      hasNavigate: !!req.headers.referer?.includes(process.env.NEXT_PUBLIC_DOMAIN),
      basePath: req.headers.host,
    },
  };
}
